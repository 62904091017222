<template>
  <div class="area-product">
    <!--  头部轮播  -->
    <div id="swiper">
      <img
        src="@/assets/images/areaProduct/banner_products_area_safety.png"
        alt="区域校园安全风险防控平台"
      />
      <div class="swiper_content">
        <h1>区域校园安全风险防控平台</h1>
        <p>
          为区域教育局建设多级安全管理风险防控平台，保障安全防控工作逐级、科学、规范开展
        </p>
      </div>
    </div>
    <div class="navigation">
      <ul>
        <li
          v-for="(nav, index) in navList"
          :key="index"
          @click="goPage(index, nav.url)"
        >
          <span
            :class="{
              'active-nav': activeNav === index,
            }"
            >{{ nav.label }}</span
          >
        </li>
      </ul>
    </div>
    <div class="content">
      <div class="box">
        <div class="area">
          <div class="title">
            <h3>区域校园安全风险防控平台</h3>
            <!-- <p class="title_en">District-school Safety Management Platform</p> -->
          </div>
          <div class="area_content">
            <!-- <div><span>该平台已累计服务**省市，**区域</span></div> -->
            <p>
              为区域教育局建设多级安全管理风险防控平台:可覆盖省级、地市级、区县级;以教育主管部门为中心,下联各级各类学校、家庭、校园安全相关的联防单位和社会组织，构建互联互通的校园安全风险防控统一平台。能够服务于全市中、小学校、学生及家长,逐级分级、分权限进行管理和应用,综合利用
              <span>【大数据+云计算+智能物联】</span>
              等先进科技手段,建立平安校园工作互通体系,保障安全防范工作逐级、科学、规范地开展。
            </p>

            <div class="platform">
              <img src="@/assets/images/areaProduct/platform_n.png" alt="" />
              <p>
                尤其是来自学校的安全工作数据通过智能终端及闭环工作流机制,及时上报、汇总到教育局端，充分实现校园安全管理工作的动态监管、过程和结果的量化评价、智能统计分析及预测。区校一体化平台可扩展为:
                <span>教育集团模式，跨区域、多学校的一体化安全管理平台。</span>
              </p>
            </div>
          </div>
        </div>
        <!--  纵向统一领导  -->
        <div class="campus">
          <img
            :src="
              $store.state.deviceWidth > 769
                ? platformBottomPc
                : platformBottomMobile
            "
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { swiper, swiperSlide, directive } from 'vue-awesome-swiper'
// import 'swiper/swiper-bundle.css'
import platformBottomPc from '@/assets/images/areaProduct/platform_bottom_pc.png'
import platformBottomMobile from '@/assets/images/areaProduct/platform_bottom_mobile.png'

export default {
  name: 'School',
  components: {
    // swiper,
    // swiperSlide,
  },
  directives: {
    // swiper: directive,
  },
  data() {
    return {
      platformBottomPc,
      platformBottomMobile,
      navList: [
        { label: '区域课程管理平台', url: '/products-plans/area' },
        { label: '区域校园安全风险防控平台', url: '/products-plans/safety' },
        {
          label: '区域课后服务云平台',
          url: '/products-plans/afterschool',
        },
      ],
      activeNav: 1,
      swiperList: [
        {
          url: '图片路径',
          title: '图片描述',
        },
      ], // 轮播图片
      swiperOption: {
        // swiper操作项
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          // dynamicBullets: true,
        },
      },
    }
  },
  mounted() {
    // 设置header背景透明
    this.$store.commit('setPcHeaderIsTransparent', true)
  },
  methods: {
    goPage(index, url) {
      this.activeNav = index
      this.$router.push(url)
    },
  },
}
</script>

<style scoped lang="scss">
%area {
  #swiper {
    img {
      width: 100%;
    }
    position: relative;
    .swiper {
      @include px2vw('height', 750);
      img {
        width: 100%;
      }
      & > .swiper-pagination {
        width: auto !important;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 0;
        ::v-deep span {
          padding: 0 20px;
          margin: 0 4px;
          border-radius: 4px;
        }
        ::v-deep .swiper-pagination-bullet-active {
          background-color: $--color-white;
        }
      }
    }
    .swiper_content {
      position: absolute;
      left: 50%;
      top: 38%;
      width: 100%;
      max-width: 1374px;
      transform: translate(-50%, -50%);
      text-align: center;
      color: $--color-white;
      z-index: 2;
      text-shadow: 0px 2px 12px rgba(0, 0, 0, 0.5);
      h1 {
        @include px2vw('font-size', 56);
      }
      p {
        @include px2vw('font-size', 28);
      }
    }
  }
  .navigation {
    width: 100%;
    max-width: 1320px;
    margin: 0px auto;
    margin-bottom: 80px;
    padding-top: 38px;
    padding-bottom: 8px;
    @include px2vw('padding-left', 90);
    @include px2vw('padding-right', 90);
    box-sizing: border-box;
    box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.08);
    & ul {
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      li {
        min-width: 120px;
        box-sizing: border-box;
        display: flex;
        margin: 0 6px;
        margin-bottom: 30px;
        cursor: pointer;
        span {
          padding: 6px 10px;
        }
        span.active-nav {
          background-color: $--color-primary;
          color: $--color-white;
        }
      }
      li::after {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background-color: #999999;
        margin-left: 10px;
      }
      li:last-child()::after {
        content: '';
        display: none;
      }
    }
  }
  .content {
    background: #f7f7f8;
    padding-bottom: 40px;
    .box {
      max-width: 1320px;
      margin: 60px auto;
      // 通用头部样式
      .title {
        text-align: center;
        color: #333;
        h3 {
          line-height: 60px;
          font-size: 40px;
          font-weight: bold;
          margin: 0;
        }
        .title_en {
          font-size: 24px;
        }
        .title_en::after {
          content: '';
          display: block;
          width: 66px;
          height: 3px;
          margin: 20px auto;
          background-color: $--color-primary;
        }
      }
      // 新高考
      .area {
        padding: 40px 0px;
        &_content {
          & > div:nth-child(1) {
            width: calc(100% - 20px);
            max-width: 800px;
            text-align: center;
            padding: 36px 0;
            border: 1px solid #979797;
            font-size: 28px;
            margin: 60px auto;
          }
          & > p {
            margin: 40px 60px;
            margin-bottom: 50px;
            line-height: 32px;
            font-size: 18px;
            text-align: justify;
            color: #666;
            span {
              color: $--color-primary;
            }
          }
          .platform {
            display: flex;
            justify-content: space-around;
            align-items: center;
            background: $--color-white;
            padding: 40px 20px;
            margin-bottom: 30px;
            & > p {
              max-width: 744px;
              line-height: 32px;
              font-size: 18px;
              text-align: justify;
              color: #666;
              text-align: justify;
              span {
                color: $--color-primary;
              }
            }
            img {
              width: 30%;
            }
          }
        }
      }
      // 智慧校园
      .campus {
        margin: 0px auto;
        text-align: center;
        img {
          width: 70%;
        }
      }
    }
  }
}
.view-pc {
  @extend %area;
  @media (min-width: 570px) and(max-width:1440px) {
    .navigation {
      max-width: 1100px;
      @include px2vw('padding-left', 50);
      @include px2vw('padding-right', 50);
    }
    .box {
      max-width: 1100px !important;
      .area {
        padding: 0 !important;
        // &_content {
        //   div:nth-child(1) span {
        //     padding: 0 20px;
        //   }
        //   p {
        //     margin-top: 30px;
        //     font-size: 18px;
        //     margin-bottom: 0;
        //   }
        // }
      }
      .platform {
        img {
          margin-right: 22px;
        }
      }
    }
  }
}
// mobile会将px转成vw
.view-mobile {
  @extend %area;
  @media screen and (max-width: 700px) {
    .area-product {
      margin-top: 44px;
      #swiper {
        .swiper {
          height: 200px;
          & > .swiper-pagination {
            bottom: 10px;
            ::v-deep span {
              padding: 0 10px;
              margin: 0 4px;
              border-radius: 4px;
            }
            ::v-deep .swiper-pagination-bullet-active {
              background-color: $--color-white;
            }
          }
        }
        .swiper_content {
          h1 {
            font-size: 22px;
          }
          p {
            font-size: 12px;
            margin: 0 20px;
            line-height: 20px;
          }
        }
      }
      .navigation {
        margin: 0 auto 40px;
        padding-left: 0;
        padding-right: 0;
        & ul {
          width: 100%;
          display: flex;
          li {
            min-width: unset;
            max-width: unset;
            margin: 0 0 10px;
            padding: 0 6px;
            text-align: center;
            border-right: 1px #999999 solid;
            &::after {
              margin-left: 0;
              background-color: $--color-white;
            }
            &:last-child {
              border-right: 0;
            }
            span {
              padding: 6px;
            }
          }
        }
      }
      .content {
        .title_en {
          font-size: 14px !important;
          margin-top: 10px;
        }
        h3 {
          line-height: 30px !important;
        }
        .box {
          width: 90%;
          .title {
            text-align: center;
            color: #333;
            h3 {
              line-height: 60px;
              font-size: 40px;
              font-weight: bold;
              margin: 0;
            }
            .title_en {
              font-size: 18px;
            }
            .title_en::after {
              content: '';
              display: block;
              width: 66px;
              height: 3px;
              margin: 20px auto;
              background-color: $--color-primary;
            }
          }
          .area {
            padding: 40px 0px;
            .area_content {
              p {
                margin: 0;
                font-size: 18px;
                line-height: 32px;
                color: #666;
                text-align: justify;
              }
              div:nth-child(1) span {
                padding: 0 20px;
              }
              .platform {
                display: block;
                padding: 20px;
                margin-top: 40px;
                img {
                  width: 100%;
                  margin-bottom: 20px;
                }
              }
            }
          }
          .campus {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
