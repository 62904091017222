var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"area-product"},[_vm._m(0),_c('div',{staticClass:"navigation"},[_c('ul',_vm._l((_vm.navList),function(nav,index){return _c('li',{key:index,on:{"click":function($event){return _vm.goPage(index, nav.url)}}},[_c('span',{class:{
            'active-nav': _vm.activeNav === index,
          }},[_vm._v(_vm._s(nav.label))])])}),0)]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"box"},[_vm._m(1),_c('div',{staticClass:"campus"},[_c('img',{attrs:{"src":_vm.$store.state.deviceWidth > 769
              ? _vm.platformBottomPc
              : _vm.platformBottomMobile,"alt":""}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"swiper"}},[_c('img',{attrs:{"src":require("@/assets/images/areaProduct/banner_products_area_safety.png"),"alt":"区域校园安全风险防控平台"}}),_c('div',{staticClass:"swiper_content"},[_c('h1',[_vm._v("区域校园安全风险防控平台")]),_c('p',[_vm._v(" 为区域教育局建设多级安全管理风险防控平台，保障安全防控工作逐级、科学、规范开展 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"area"},[_c('div',{staticClass:"title"},[_c('h3',[_vm._v("区域校园安全风险防控平台")])]),_c('div',{staticClass:"area_content"},[_c('p',[_vm._v(" 为区域教育局建设多级安全管理风险防控平台:可覆盖省级、地市级、区县级;以教育主管部门为中心,下联各级各类学校、家庭、校园安全相关的联防单位和社会组织，构建互联互通的校园安全风险防控统一平台。能够服务于全市中、小学校、学生及家长,逐级分级、分权限进行管理和应用,综合利用 "),_c('span',[_vm._v("【大数据+云计算+智能物联】")]),_vm._v(" 等先进科技手段,建立平安校园工作互通体系,保障安全防范工作逐级、科学、规范地开展。 ")]),_c('div',{staticClass:"platform"},[_c('img',{attrs:{"src":require("@/assets/images/areaProduct/platform_n.png"),"alt":""}}),_c('p',[_vm._v(" 尤其是来自学校的安全工作数据通过智能终端及闭环工作流机制,及时上报、汇总到教育局端，充分实现校园安全管理工作的动态监管、过程和结果的量化评价、智能统计分析及预测。区校一体化平台可扩展为: "),_c('span',[_vm._v("教育集团模式，跨区域、多学校的一体化安全管理平台。")])])])])])
}]

export { render, staticRenderFns }